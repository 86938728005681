import React from 'react'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import { useStaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocalState } from '../components/Layout'
import { TableOfIssueContents } from '../components/TableOfContents'
import { useTransitionClasses } from '../hooks/useTransitionClasses'
import { identifyFirstArticle } from '../utils/identifyFirstArticle'

const ALL_INTERVIEWS_QUERY = graphql`
	query AllInterviewQuery {
    allPrismicIssue(
			sort: { order: DESC, fields: data___issue_number___text }
		) {
			nodes {
        _previewable
				id
        uid
        type
				data {
          issue_number {
            text
          }
          issue_title {
            text
          }
					articles {
						article {
							uid
              type
							document {
								... on PrismicInterview {
									id
									type
									uid
                  first_publication_date
									data {
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
								}
              }
            }
          }
        }
      }
    }
  }
`

const InterviewArchive = ({ transitionStatus }) => {
  const { previewIssue } = useLocalState(),
    staticData = useStaticQuery(ALL_INTERVIEWS_QUERY),
    { data } = useMergePrismicPreviewData(staticData),
    issues = previewIssue
      ? [previewIssue, ...data.allPrismicIssue.nodes]
      : data.allPrismicIssue.nodes,
    articles = issues.flatMap(issue => issue?.data?.articles),
    firstArticle = identifyFirstArticle(articles, `interview`),
    transitionClasses = useTransitionClasses({ transitionStatus })

	return (
		<div className={transitionClasses}>
			<Seo
        title="Interviews"
        image={firstArticle?.document?.data?.featured_image}
      />
			<TableOfIssueContents issues={issues} tight type="interview" />
      <Footer />
		</div>
	)
}

export default InterviewArchive
